<template>
    <div style="margin-top: 50px">
        <Card style="width: 97%;margin-bottom:40px;text-align:center;margin-left:3%;float: left;padding-top: 10px">
            <span style="color: darkgray;">Total amount (USD)</span>
            <div style="font-size: 1.95rem;margin-bottom:20px;padding-top:4px;font-weight: 600;color: #e9ab01">
                <div style="padding-top: 15px;display: inline">{{amount}}</div>
            </div>
            <div style="width: 30%;margin-left:10%;float: left;margin-bottom: 50px">
                <Button long @click="topUp">Deposit</Button>
            </div>
            <Modal v-model="topUp_tip" width="360" style="color: darkgray">
                <p slot="header" style="color:#e9ab01;text-align:center">
                    <Icon type="ios-information-circle"></Icon>
                    <span>Deposit confirmation</span>
                </p>
                <div style="text-align:left">
<!--                    <span style="color: darkgray;width: 100%;font-size:1.025rem;float: left">Paying Email Account</span>-->
                    <!--                    <Select v-model="aim_email" clearable>
                                            <Option v-for="item in emailList" :value="item.value" :key="item.value">{{ item.value}}</Option>
                                        </Select>-->
<!--                    <AutoComplete-->
<!--                            v-model="aim_email"-->
<!--                            :filter-method="filterMethod"-->
<!--                            :data="emailFilter">-->
<!--                    </AutoComplete>-->
                    <span style="color: darkgray;width: 100%;font-size:1.025rem;float: left; margin-top: 20px">Deposit amount</span>
                    <Poptip trigger="focus">
                        <Input v-model="topUp_amount" prefix="logo-usd" placeholder="Enter number"/>
                        <div slot="content">{{ topUp_amount }}</div>
                    </Poptip>
                    <div>${{amount}} left in Balance</div>
                </div>
                <div slot="footer" style="text-align: center">
                    <Button type="primary" size="large" @click="submit_topUp">Deposit</Button>
                </div>
            </Modal>
            <div style="width: 30%;margin-left:20%;float: left;margin-bottom: 50px">
                <Button long type="primary" @click="withdraw">Withdraw</Button>
            </div>
            <Modal v-model="withdraw_tip" width="360" style="color: darkgray">
                <p slot="header" style="color:#e9ab01;text-align:center">
                    <Icon type="ios-information-circle"></Icon>
                    <span>Withdraw confirmation</span>
                </p>
                <div style="text-align:left">
                    <span style="color: darkgray;width: 100%;font-size:1.025rem;float: left">Receiving Email Account</span>
                    <!--                    <Select v-model="aim_email" clearable>
                                            <Option v-for="item in emailList" :value="item.value" :key="item.value">{{ item.value}}</Option>
                                        </Select>-->
                    <AutoComplete
                            v-model="aim_email"
                            :filter-method="filterMethod"
                            :data="emailFilter">
                    </AutoComplete>
                    <span style="color: darkgray;width: 100%;font-size:1.025rem;float: left; margin-top: 20px">Withdrawal amount</span>
                    <Poptip trigger="focus">
                        <Input v-model="withdraw_amount" prefix="logo-usd" placeholder="Enter number"/>
                        <div slot="content">{{ withdraw_amount }}</div>
                    </Poptip>
                    <div>${{amount}} left in Balance,<a style="color:black" @click="withdraw_All"> Withdraw All</a>
                    </div>
                </div>
                <div slot="footer" style="text-align: center">
                    <Button type="primary" size="large" @click="submit_withdraw">Withdraw</Button>
                </div>
            </Modal>
        </Card>
        <!--
                <div style="width: 100%;padding-left:3%;text-align:left;float: left">
                    <div style="width: 100%;float: left; margin-bottom: 20px">
                        <span style="float: left;font-size:1.125rem;font-weight:400;width: 50%;text-align: left">Your PayPal Account</span>

                        <div style="float: left;width: 50%; text-align: right;padding-right: 2%">
                            <Button type="primary" @click="let_add"> Add </Button>
                            <Modal v-model="add_lag" width="360" style="color: darkgray">
                                <p slot="header" style="color:#e9ab01;text-align:center">
                                    <Icon type="ios-information-circle"></Icon>
                                    <span>Add a new account.</span>
                                </p>
                                <div style="text-align:left">
                                    <span style="color: darkgray;width: 100%;font-size:1.025rem;float: left">Email Account</span>
                                    <Input v-model="add_email_name">

                                    </Input>
                                </div>
                                <div slot="footer" style="text-align: center">
                                    <Button type="primary" size="large" @click="add_email">Add</Button>
                                </div>
                            </Modal>
                            <Button  style="margin-left: 10%" @click="manage_email"> Manage</Button>
                        </div>
                    </div>
                    <div style="width: 100%;float: left;text-align: right">
                        <Table :columns="email_col" :data="emailList"></Table>
                    </div>
                    <div v-if="delete_lag === true" style="margin-top:40px;width: 30%;margin-left:35%;float: left;text-align: center">
                        <Button long type="primary" @click="delete_email">Delete</Button>
                    </div>

                </div>
        -->

    </div>

</template>

<script>
    import CountD from "./countD";
    import VotingPic from "./votingPic";

    export default {
        name: "userPackages",
        components: {VotingPic, CountD},
        data() {
            return {
                amount: 0,
                emailList: [],
                emailFilter: [],
                aim_email: null,
                withdraw_tip: false,
                withdraw_amount: 0,
                topUp_tip: false,
                topUp_amount: 0,
                delete_lag: false,
                add_lag: false,
                add_email_name: null,

            }
        },
        methods: {
            topUp() {
                this.topUp_tip = true;

            },
            submit_topUp() {
                // this.$axios.post(
                //      "/user/addBalance",
                //      {
                //              amount: this.topUp_amount,
                //              id: this.getCookie("user_id")
                //      }
                //  ).then((response) => {
                //      //成功登陆
                //     this.topUp_amount = 0;
                //     this.loadInfo();
                //  }).catch((error) => {
                //      this.$Message.error(error.message);
                //  });
                let Tself = this;
                if (this.topUp_amount <= 0) {
                    this.$Message.error("The amount cannot be non-positive.")
                    return
                }
                let paymentData = {
                    money: this.topUp_amount,
                    description: "PayPal Payment",
                    user: this.getCookie("user_id")
                }
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/payment/pay',
                    data: paymentData
                }).then(function (response) {
                    let result = response.data.data.items;
                    if(result.indexOf("error")===-1){
                        Tself.topUp_amount = 0;
                        Tself.loadInfo();
                        window.location.href = response.data.data.items;
                    }else{
                        Tself.$Message.error("Wrong Deposit!");
                    }
                }).catch((error) => {
                    this.$Message.error(error.message);
                });

            },
            withdraw() {
                this.withdraw_tip = true;
            },
            withdraw_All() {
                this.withdraw_amount = this.amount;
            },
            getCookie(cname) {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i].trim();
                    if (c.indexOf(name) !== -1) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            submit_withdraw() {
                if (isNaN(this.withdraw_amount) || this.aim_email === null) {
                    this.$Message.error('Wrong withdraw!');
                } else {
                    if (this.withdraw_amount <= 0) {
                        this.$Message.error("The amount cannot be non-positive.")
                        return
                    }
                    let withdrawData = {
                        money: this.withdraw_amount,
                        user : this.getCookie("user_id"),
                        email: this.aim_email,
                    }
                    this.$axios.post(
                        "/payment/withdraw",
                        withdrawData
                    ).then((response) => {
                        console.log(response)
                        let result = response.data.data;
                        if(result === "create withdraw success"){
                            //成功取钱
                            this.withdraw_amount = 0;
                            this.$Message.success('Success! Your application will be completed within seven working days!');
                            this.loadInfo();
                        }
                        else {
                            this.$Message.error("Wrong withdraw!")
                        }
                    }).catch((error) => {
                        this.$Message.error(error.message);
                    });

                    // this.$axios.post(
                    //     "/user/subBalance",
                    //     {
                    //         amount: this.withdraw_amount,
                    //         id: this.getCookie("user_id"),
                    //
                    //     }
                    // ).then((response) => {
                    //     //成功取钱
                    //     this.withdraw_amount = 0;
                    //     this.$Message.success('Success!');
                    //     this.loadInfo();
                    // }).catch((error) => {
                    //     this.$Message.error(error.message);
                    // });
                    this.withdraw_tip = false;
                }
            },
            manage_email() {
                this.delete_lag = !this.delete_lag;
            },
            delete_email() {
                //do delete receiving emails
                this.$Message.success('Success!');
                this.delete_lag = false;
            },
            let_add() {
                this.add_lag = true;
            }
            ,
            add_email() {
                //todo
                //修改添加邮箱信息
                if (0 === 1) {
                    this.$Message.error('Wrong withdraw!');
                } else {
                    //do withdraw

                    this.$Message.success('Success!');
                    this.add_lag = false;
                    this.add_email_name = null;
                }
            },
            filterMethod(value, option) {
                return option.indexOf(value) !== -1;
            },
            loadInfo() {
                this.$axios.post(
                    "/user/findById",
                    this.getCookie("user_id"),
                    {
                        headers: {
                            'Content-Type': 'text/xml'
                        }
                    }
                ).then((response) => {
                    let uf = response.data.data.items;
                    this.amount = uf.balance;
                }).catch((error) => {
                    console.log(error);
                    this.$Message.error(error.message);
                })
            },
            make_fake() {
                this.amount = 5000;
                this.emailList = [
                    {
                        value: "wuwuwu777@163.com"
                    },
                    {
                        value: "wuwuwu777@qq.com"
                    },
                    {
                        value: "wuwuwu777@nju.edu.com"
                    },

                ];
                this.emailFilter = ["wuwuwu777@163.com", "wuw89898@163.com", "wuwuwu9997@163.com"]

            },
        },
        mounted() {
            this.loadInfo();
        },
        computed: {
            email_col() {
                let email_col = [
                    {
                        title: 'Email',
                        key: 'value'
                    },
                ]
                if (this.delete_lag) {
                    email_col.push(
                        {
                            type: 'selection',
                            width: 60,
                            align: 'center'
                        })
                }
                return email_col;

            }
        }
    }
</script>

<style scoped>
    .account_total {
        width: 35%;
        padding: 30px 5%;
        float: left;
    }


</style>
